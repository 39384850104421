
import fnApi from '/utils/common/methods'
import OrderListTab from './components/OrderListTab'
import OrderListSection from './components/OrderListSection'
import OrderListRec from './components/OrderListRec'

export default {
    name: 'OrderList',
    components: {
        OrderListTab,
        OrderListSection,
        OrderListRec
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            query: {},
            currency: {},
            country: {},
            fnApi: fnApi,
            middleEmit: {},
            order: {
                type: 1
            },
            hasLogin: false,
            search: {}
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {},
    methods: {
        handleSearchBtn() {
            this.$router.push({
                name: 'OrderSearch'
            })
        },
        // 加购
        handleBag(obj) {
            window.bagClick({
                ...obj
            })
        },
        // back
        handleBack() {
            let ref = this.$refs.OrderListSection;
            if(this.order.type == 2) {
                ref.handleGiftCard(1)
            } else {
                this.$router.back()
            }
        },
        // tab change
        emitChangeTab(obj) {
            let ref = this.$refs.OrderListSection;
            ref.emitChangeTab(obj)
        },
        emitInitialParams(obj) {
            let ref = this.$refs.OrderListSection;
            ref.emitInitialParams(obj)
            this.search = obj
        },
        emitSearch(obj) {
            let ref = this.$refs.OrderListSection;
            ref.emitSearch(obj)
        },
        emitGetOrderList(obj) {
            let ref = this.$refs.OrderListSection;
            ref.emitInitialParams(obj)
            ref.emitGetOrderList()
            this.search = obj
        },
        handleGiftCard(obj) {
            this.order = obj
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('My Order'),
                keywords: this.$translate('My Order'),
                description: this.$translate('My Order'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            this.query = this.$route.query
            this.hasLogin = this.$login()
            this.middleEmit.fn = (fn, options) => {
                return this[fn] && this[fn](options)
            }
            if(process.client) {
                this.currency = this.$storage.get('currency') || {}
                this.country = this.$storage.get('country') || {}
            }
        },
    },
}
