

export default {
    name: 'OrderListTab',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        lang: Object,
        query: Object,
        middleEmit: Object,
        search: Object,
    },
    data() {
        return {
            tab: {
                type: '',
                list: []
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        changeTab() {
            this.middleEmit.fn('emitChangeTab', this.tab)
            this.updateUrl()
        },
        // 变更地址栏参数
        updateUrl() {
            this.$router.replace({
                name: 'OrderList',
                query: {
                    type: this.tab.type
                }
            })
        },
        // 初始化
        initialSet() {
            this.tab.list = [{
                statusText: this.$translate('All'),
                type: ''
            }, {
                statusText: this.$translate('Processing'),
                type: 'Unshipped'
            }, {
                statusText: this.$translate('Shipped'),
                type: 'Shipped'
            }, {
                statusText: this.$translate('Delivered'),
                type: 'WaitComment'
            }, {
                statusText: this.$translate('Returns'),
                type: 'Close'
            }]
            if(this.query.type) {
                this.tab.type = this.query.type
            }
        },
    },
}
