
import OrderListSet from './OrderListSet'

export default {
    name: 'OrderListSection',
    components: {
        OrderListSet
    },
    asyncData({api, cookies, ...context}) {},
    props: {
        currency: Object,
        fnApi: Object,
        query: Object,
        hasLogin: Boolean,
        middleEmit: Object,
    },
    data() {
        return {
            skeleton: true,
            orderState: '',
            hasPull: false,
            hasEventPull: false,
            order: {
                visible: false,
                countGiftCardOrder: 0,
                type: 1, // 1 商品订单 2 礼品卡订单
                list: []
            },
            page: {
                pageSize: 20,
                pageNum: 1,
                totalPage: 1
            },
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        this.hasLogin && this.getOrderListAjax()
    },
    methods: {
        // 获取列表
        getOrderListAjax() {
            const key = ['getOrderList', 'getGiftCardOrderList'][this.order.type - 1]
            this.$api.order[key]({
                orderState: this.orderState,
                ...this.page
            }).then(response => {
                const res = response.result || {};
                this.setTotalData(res)
            })
        },
        setTotalData(res) { // 设置总数据
            this.skeleton = false
            !this.hasPull && (this.order.list = [])
            this.order.list = [...this.order.list, ...(res.list || [])]
            this.order.visible = !!this.order.list.length
            this.order.countGiftCardOrder = res.countGiftCardOrder || 0
            this.page.totalPage = res.totalPage
            this.setData()
            !this.hasEventPull && this.loadMore()
        },
        setData() { // 设置数据
            this.order.list.forEach(item => {
                let status = this.setStatus(item.orderStatus);
                item.statusText = status.text
                item.statusType = status.type
            })
        },
        // 设置订单状态
        setStatus(val) {
            // type-> 订单状态 1 待支付 2 待发货 3 待收货 4 完成 5 已取消 6 退款关闭  7 待评价 8 待审核 9 礼品卡待发送 10 礼品卡已发送 11 礼品卡已兑换
            let statusTextArr = ['Unpaid', 'Processing', 'Shipped', 'Delivered', 'Cancelled', 'Refunded', 'Reviews', 'Pending', 'Not Sent', 'Sent', 'Used'];
            let status = ['pending', 'unshipped', 'shipped', 'done', 'canceled', 'close', '', 'pendingaudit', 'waiting send', 'send', 'redeemed'];
            let type = status.indexOf(val.toLowerCase()) || 0;
            return {
                type: type + 1,
                text: this.$translate(statusTextArr[type])
            }
        },
        // tab change
        emitChangeTab(obj) {
            this.orderState = obj.type
            this.page.pageNum = 1
            this.hasPull = false
            this.getOrderListAjax()
        },
        // 礼品卡点击
        handleGiftCard(v = 2) {
            // v -> 1 商品订单 2 礼品卡订单
            this.order.type = v
            this.page.pageNum = 1
            this.getOrderListAjax()
            this.middleEmit.fn('handleGiftCard', this.order)
            const query = this.$route.query;
            this.$router.push({
                name: 'OrderList',
                query: {
                    ...query,
                    entry: v
                }
            })
        },
        // 跳转订单详情
        jumpDetail(obj) {
            this.$router.push({
                name: 'OrderDetail',
                query: {
                    id: obj.originOrderId
                }
            })
        },
        // 触底加载更多
        loadMore() {
            this.$nextTick(() => {
                if(this.hasEventPull) {
                    return
                }
                this.hasEventPull = true
                this.fnApi.pullRefresh.load((e) => {
                    if(e.isIntersecting && this.page.totalPage > this.page.pageNum) {
                        this.page.pageNum += 1
                        this.hasPull = true
                        this.getOrderListAjax()
                    }
                }, {
                    root: '.orderList'
                })
            })
        },
        // 初始化
        initialSet() {
            if(this.query.type) {
                this.orderState = this.query.type
            }
            this.query.entry && (this.order.type = this.query.entry)
            this.middleEmit.fn('handleGiftCard', this.order)
        }
    },
}
