
export default {
    name: 'OrderSearchRecently',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmit: Object,
        search: Object,
    },
    data() {
        return {
            tag: {
                visible: false,
                list: []
            }
        }
    },
    computed: {},
    watch: {
        search: {
            handler() {
                this.initialSet()
            },
            deep: true
        }
    },
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        // delete
        handleDelete() {
            this.tag.list = []
            this.tag.visible = false
            this.$storage.remove('order_search_recently')
        },
        // tag
        handleTag(val) {
            this.search.status = true
            this.search.key = val
            this.middleEmit.fn('emitSearch', this.search)
        },
        // 历史记录
        setRecently() {
            if(this.search.key && !this.tag.list.includes(this.search.key)) {
                this.tag.list.unshift(this.search.key)
                this.tag.list.splice(21, 22)
                this.$storage.set('order_search_recently', this.tag.list)
            }
        },
        // 初始化
        initialSet() {
            let recently = this.$storage.get('order_search_recently');
            this.tag.list = recently || []
            this.tag.visible = !!this.tag.list.length
        }
    }
}
