
import OrderSearchHead from './components/OrderSearchHead'
import OrderSearchRecently from './components/OrderSearchRecently'
import OrderSearchSection from './components/OrderSearchSection'
import OrderSearchRec from './components/OrderSearchRec'

export default {
    name: 'OrderSearch',
    components: {
        OrderSearchHead,
        OrderSearchRecently,
        OrderSearchSection,
        OrderSearchRec
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            middleEmit: {},
            query: {},
            currency: {},
            hasLogin: false,
            search: {
                status: false,
                key: ''
            }
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {
        this.hasLogin = this.$login()
        this.currency = this.$storage.get('currency') || {}
    },
    methods: {
        // emit
        emitSearch(obj) {
            this.search = obj
            const ref = this.$refs.OrderSearchRecently;
            const refSec = this.$refs.OrderSearchSection;
            ref.setRecently()
            refSec.emitSearch()
        },
        emitSearchFocus(obj) {
            this.search.status = obj.status
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('Order Search'),
                keywords: this.$translate('Order Search'),
                description: this.$translate('Order Search'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            this.query = this.$route.query
            this.middleEmit.fn = (fn, options) => {
                this[fn] && this[fn](options)
            }
        }
    },
}
