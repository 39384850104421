
export default {
    name: 'payTransit',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            hasLoading: false,
            hasApp: false,
            awEnv: '',
            params: {}
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initAW()
        this.initialSet()
    },
    methods: {
        // 获取APP传递参数
        getConfig() {
            this.$fnApi.bridgeApp.bridge({
                name: 'getConfig',
                hasGetData: true
            }).then(e => {
                this.params.id = e.id
                this.params.client_secret = e.client_secret
                this.params.intent_id = e.intent_id
                this.params.currency = e.currency
                this.payAw()
            })
        },
        initAW() { // 空中云汇支付初始化
            let interval = setInterval(() => {
                if(Airwallex) {
                    clearInterval(interval)
                    Airwallex.init({
                        env: this.awEnv, // Setup which Airwallex env('demo' | 'prod') to integrate with
                        origin: window.location.origin, // Set up your event target to receive the browser events message
                    });
                }
            }, 1000)
        },
        // 拉起空中云汇支付
        payAw() {
            let redirectUrl = `${window.location.origin}/paysuccess?order_id=${this.params.id}`
            this.$fnApi.debounce(() => {
                Airwallex.redirectToCheckout({
                    env: this.awEnv,
                    mode: 'payment',
                    intent_id: this.params.intent_id,
                    client_secret: this.params.client_secret,
                    currency: this.params.currency,
                    successUrl: redirectUrl,
                    failUrl: redirectUrl,
                    cancelUrl: redirectUrl,
                })
            }, 3000)
        },
        // 初始化
        initialSet() {
            this.hasLoading = true
            this.awEnv = !['10.10', 'app-php', 'mmall.dev.selleroa.com'].includes(window.location.host) ? 'prod' : 'demo'
            this.hasApp = this.$fnApi.bridgeApp.getApp().isApp
            if(this.hasApp) {
                this.getConfig()
            } else {
                this.hasLoading = false
            }
        }
    }
}
