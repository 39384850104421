
export default {
    name: 'OrderListRec',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        currency: Object,
        middleEmit: Object,
    },
    data() {
        return {
            rec: {
                hasRefresh: false,
                pageNum: 1,
                pageSize: 20,
                list: [],
                visible: false
            },
            listCompo: { // yfn-list组件配置
                options: {
                    hasMore: true,
                    hasConcat: true
                },
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getRecAjax()
    },
    methods: {
        // 获取推荐数据
        getRecAjax() {
            this.$api.common.getRecV5Common({
                index: 7,
                pageNum: this.rec.pageNum,
                pageSize: this.rec.pageSize
            }).then(response => {
                const res = response.result || {};
                this.rec.list = [...this.rec.list, ...(res.list || [])]
                this.rec.totalPage = res.totalPage
                this.rec.visible = !!this.rec.list.length
            })
        },
        emitBottomLoading(e, callBack) {
            const hasNext = this.rec.totalPage > this.rec.pageNum;
            if(e.isIntersecting && hasNext) {
                this.rec.pageNum += 1
                this.getRecAjax()
            }
            callBack(!hasNext)
        },
    },
}
