
export default {
    name: 'OrderSearchSet',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        info: Object,
        currency: Object,
        hasLogin: Boolean,
    },
    data() {
        return {
            active: 0,
            showPopover: false,
            set: {
                visible: false,
                showLength: 0,
                class: '',
                list: []
            }
        }
    },
    computed: {},
    watch: {
        info: {
            handler(n, o) {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        // 设置数据
        setData() {
            // type > 操作状态 1 购买 2 取消订单 3 退款 4 重购 5 更改地址 8 取消订单 7 评价 9 查看物流 10 退货 11 未评价 12 已评价
            // statusType -> 订单状态 1 待支付 2 待发货 3 待收货 4 完成 5 已取消 6 退款关闭  7 待评价 8 待审核
            let rs = this.info;
            let statusType = rs.statusType;
            let statusComm = this.info.yfnComm; // true 已评价 false 待评价
            let paymentMethod = this.info.paymentMethod;
            this.set.list = [{
                text: this.$translate('Repurchase'),
                type: 4,
                visible: true
            }, {
                text: this.$translate('Return'),
                type: 10,
                visible: [3, 4].includes(statusType)
            },{
                text: this.$translate('Cancel'),
                type: 2,
                visible: statusType == 2 && paymentMethod == 'COD'
            }, {
                text: this.$translate('Refund'),
                type: 3,
                visible: [2].includes(statusType) && paymentMethod != 'COD'
            }, {
                text: this.$translate('Edit Address'),
                type: 5,
                visible: [2, 8].includes(statusType)
            }, {
                text: this.$translate('Track'),
                type: 9,
                visible: [3, 4].includes(statusType)
            }, {
                text: this.$translate('Review'),
                type: 11,
                visible: [4].includes(statusType) && !statusComm
            }, {
                text: this.$translate('My Review'),
                type: 12,
                visible: [4].includes(statusType) && statusComm
            }]
            // visible 显示 plain 状态
            this.set.showLength = this.set.list.filter(item => {
                return item.visible
            }).length
            this.set.visible = !!this.set.showLength
            let index = 0;
            this.set.list.forEach((item, i) => {
                if(item.visible) {
                    index ++
                    item.i = index
                }
                item.plain = index == this.set.showLength
            })
            this.set.class = index > 3 ? 'more' : 'normal'
        },
        getContainer() {
            return document.querySelector('.OrderSearchSet-over-point');
        },
        // 设置操作
        handleOrderSet(obj) {
            // obj.type 1 购买 2 取消订单 3 退款 4 重购 5 更改地址 8 取消订单 7 评价 9 查看物流 10 退货 11 未评价 12 已评价
            switch(obj.type) {
                case 1:
                    this.setOrderSubmit()
                    break;
                case 2:
                    this.setOrderCancel()
                    break;
                case 3:
                    this.setOrderRefund()
                    break;
                case 4:
                    this.setOrderRepurchase()
                    break;
                case 5:
                    this.setOrderAddress()
                    break;
                case 8:
                    this.setOrderCancel()
                    break;
                case 9:
                    this.jumpTrack()
                    break;
                case 10:
                    this.setOrderReturn()
                    break;
                case 11:
                    this.jumpReviewEdit()
                    break;
                case 12:
                    this.jumpReview()
                    break;
                default:
            }
        },
        // 购买 Pay Now
        setOrderSubmit() {
            if(this.info.paymentMethod == 'Paypal') {
                // paypal支付
                let params = {
                    amount: Number(this.info.amtCent) + Number(this.info.shippingFee),
                    subTotal: this.info.subTotal,
                    articleAmt: Number(this.info.amtCent),
                    shipping: Number(this.info.shippingFee),
                    shippingDiscount: 0,
                    currency: this.info.currencySymbol,
                    orderSeq: this.info.originOrderId
                }
                this.jumpPaypalPay(params);
                return
            }
            if(this.info.paymentMethod == 'Stripe') {
                // 信用卡支付
                let params = {
                    amtCent: this.info.amtCent,
                    subTotal: this.info.subTotal,
                    address: this.info.address,
                    amt: this.info.amt,
                    orderItems:this.info.orderItems,
                    fee2: this.info.shippingFee,
                    percentAmount: Number(this.info.couponDiscount / 100).toFixed(2),
                    originOrderId: this.info.originOrderId
                }
                this.$storage.set('placeOrderInfo', params);
                this.$router.push({
                    name: 'CardPay',
                    query: {
                        from: 'detail'
                    }
                })
            }
        },
        jumpPaypalPay(obj) { // 立即购买-paypal
            this.$api.order.setPayPalPay(obj).then(response => {
                const res = response.result || {};
                this.$router.push({
                    name: 'Paypal',
                    query: {
                        cmd: res.cmd,
                        token: res.tokenId
                    }
                })
            })
        },
        // 取消订单 Cancel / Cancel Order
        setOrderCancel() {
            this.$Dialog.confirm({
                message: `${this.$translate('Dear customer, If you want to cancel this order, please contact us')}:\nservice@yfn.com`,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Copy Email',
                cancelButtonColor: '#999',
                confirmButtonColor: '#333',
            }).then(() => {
                this.$fnApi.copy.initial('service@yfn.com')
                this.$Toast(this.$translate('Copied'))
            }).catch(() => {});
        },
        // 退款 Refund
        setOrderRefund() {
            this.$Dialog.confirm({
                message: `${this.$translate(`Dear customer, If you want to refund this order, please contact us`)}:\nservice@yfn.com`,
                confirmButtonText: this.$translate('Copy Email'),
                confirmButtonColor: '#333',
                cancelButtonText: this.$translate('Cancel'),
                cancelButtonColor: '#999'
            }).then(() => {
                this.$fnApi.copy.initial('service@yfn.com')
                this.$Toast(this.$translate('Copied'))
            }).catch(() => {
            })
        },
        // 重购 Repurchase
        setOrderRepurchase() {
            this.$api.order.setOrderRepurchase({
                data: JSON.stringify({
                    orderId: this.info.id
                })
            }).then(response => {
                const res = response.result || {};
                let msg = `<div class="dialog-title" style="display:flex;align-items: center;justify-content: center;flex-wrap: wrap;">
                    <img src="https://cdn2.selleroa.com/yfn-upload/review/1689581965770.png" width="16px" height="16px">
                    <p style="font-size: 14px;margin: 0;margin-left: 5px;line-height: normal;">${this.$translate(`Added to bag successfully`)}</p>
                </div>`;
                this.$Dialog.confirm({
                    message: msg,
                    confirmButtonText: this.$translate('View Bag'),
                    confirmButtonColor: '#333',
                    cancelButtonText: this.$translate('Cancel'),
                    cancelButtonColor: '#999'
                }).then(() => {
                    this.$router.push({
                        name: 'Bag'
                    })
                }).catch(() => {
                })
            })
        },
        // 更改地址 Edit Address
        setOrderAddress() {
            this.$Dialog.confirm({
                message: `${this.$translate(`Dear customer, If you want to edit shipping address, please contact us`)}:\nservice@yfn.com`,
                confirmButtonText: this.$translate('Copy Email'),
                confirmButtonColor: '#333',
                cancelButtonText: this.$translate('Cancel'),
                cancelButtonColor: '#999'
            }).then(() => {
                this.$fnApi.copy.initial('service@yfn.com')
                this.$Toast(this.$translate('Copied'))
            }).catch(() => {
            })
        },
        // 查看物流 Track
        jumpTrack() {
            this.$router.push({
                name: 'TrackSearch',
                query: {
                    order_id: this.info.originOrderId,
                    from: 'orderDetail'
                }
            })
        },
        // 退货 Return
        setOrderReturn() {
            let rs = this.info;
            let statusType = rs.statusType;
            let message = statusType == 3 ? `Dear customer, this order has been shipped, If you want to return this item(s), please contact us` :
                `Dear customer, If you want to return this order, please contact us`
            this.$Dialog.confirm({
                message: `${this.$translate(message)}:\nservice@yfn.com`,
                confirmButtonText: this.$translate('Copy Email'),
                confirmButtonColor: '#333',
                cancelButtonText: this.$translate('Cancel'),
                cancelButtonColor: '#999'
            }).then(() => {
                this.$fnApi.copy.initial('service@yfn.com')
                this.$Toast(this.$translate('Copied'))
            }).catch(() => {
            })
        },
        // 未评价 Review
        jumpReviewEdit() {
            this.$router.push({
                name: 'Review-Edit',
                query: {
                    order_id: this.info.originOrderId,
                    order_seq: this.info.originOrderId
                }
            })
        },
        // 已评价 My Review
        jumpReview() {
            this.$router.push({
                name: 'Review-Detail',
                query: {
                    order_id: this.info.id,
                    order_seq: this.info.originOrderId
                }
            })
        },
    }
}
