
export default {
    name: 'OrderSearchHead',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmit: Object,
        search: Object
    },
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        handleSearch() {
            this.search.status = true;
            this.middleEmit.fn('emitSearch', this.search)
        },
        focusSearch() {
            this.search.status = false;
            this.middleEmit.fn('emitSearchFocus', this.search)
        },
        inputSearch(e) {
            this.search.key = e
        }
    },
}
